import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { IoMdMail } from "react-icons/io";
import { FaInstagram, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Offcanvas from 'react-bootstrap/Offcanvas';
import BASE_URL from '../constant/index'

const HeaderNavBar = () => {
    const [showUpperNavbar, setShowUpperNavbar] = useState(true);

    const [offcanvasShow, setOffcanvasShow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setShowUpperNavbar(window.scrollY <= 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <>
            <header className={`site_header ${showUpperNavbar ? 'header-scrolled' : 'header-not-scrolled'}`}>
                <Container className={`upperNavbar ${showUpperNavbar ? '' : 'upperNavbar-hidden'}`}>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            {/* <p className='HeaderIconsLink'><IoMdHome /> Ground Floor, New World</p>
                            <p className='HeaderIconsLink'><FaPhoneAlt /> <a href={`tel:998557778345`}> +998557778345</a></p> */}
                            <a href="mailto:support@fxt.exchange" className='HeaderIconsLink'><IoMdMail className="me-2" />support@fxt.exchange</a>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <a href="https://instagram.com/tradefox509" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://t.me/fxtexc" target="_blank" rel="noopener noreferrer">
                                <FaTelegramPlane />
                            </a>
                            {/* <a href="https://twitter.com/foxtrade5" target="_blank" rel="noopener noreferrer">
                                <FaXTwitter />
                            </a> */}
                            <a href="https://chat.whatsapp.com/CkUgaMwcZMrF2mCsdpRU77" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp />
                            </a>
                           
                        </div>
                    </div>

                </Container>

                <Navbar
                    key={'lg'}
                    fixed="top"
                    expand="lg"
                    className="bg-body-tertiary"
                >
                    <Container>
                        <Navbar.Brand href={BASE_URL.BASE_URL}>
                            {showUpperNavbar ?
                                <img src={`${BASE_URL.BASE_URL}img/image2.png`} width='250' className='img-fluid'   alt='about-image'/> :
                                <img src={`${BASE_URL.BASE_URL}img/Logo_Black .png`} width='250' className='img-fluid'  alt='about-image'/>
                            }

                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} onClick={() => setOffcanvasShow(true)} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${"lg"}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
                            placement="end"
                            show={offcanvasShow}
                            onHide={() => setOffcanvasShow(false)}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>

                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1">
                                    <NavLink to={BASE_URL.BASE_URL} end activeClassName="active-link" className='headerlink HeaderNavLink' onClick={() => setOffcanvasShow(false)}> Home</NavLink>
                                    <NavLink to={`${BASE_URL.BASE_URL}about`} activeClassName="active-link" className='headerlink HeaderNavLink' onClick={() => setOffcanvasShow(false)}>About us</NavLink>
                                    <NavDropdown
                                        title="Investment"
                                        id={`offcanvasNavbarDropdown-expand-${"lg"}`}
                                        className="HeaderNavLink mt-lg-1"
                                    >

                                        <NavDropdown.Item href={`${BASE_URL.BASE_URL}crypto-trading`} onClick={() => setOffcanvasShow(false)}>Crypto Trading</NavDropdown.Item>

                                        <NavDropdown.Item href={`${BASE_URL.BASE_URL}real-estate`} onClick={() => setOffcanvasShow(false)}>
                                            Real Estate
                                        </NavDropdown.Item>

                                        <NavDropdown.Item href={`${BASE_URL.BASE_URL}investment-commodity`} onClick={() => setOffcanvasShow(false)}>
                                            Commodity
                                        </NavDropdown.Item>

                                        <NavDropdown.Item onClick={() => setOffcanvasShow(false)} href={`${BASE_URL.BASE_URL}investment-currency-trading`}>
                                            Currency Trading
                                        </NavDropdown.Item>

                                        <NavDropdown.Item onClick={() => setOffcanvasShow(false)} href={`${BASE_URL.BASE_URL}mutual-funds`}>
                                            Mutual Funds
                                        </NavDropdown.Item>

                                        <NavDropdown.Item onClick={() => setOffcanvasShow(false)} href={`${BASE_URL.BASE_URL}equity-trading`}>
                                            Equity Trading
                                        </NavDropdown.Item>

                                    </NavDropdown>
                                    <NavLink to={`${BASE_URL.BASE_URL}contact`} activeClassName="active-link" className='headerlink HeaderNavLink' onClick={() => setOffcanvasShow(false)}>
                                        Contact
                                    </NavLink>
                                    <NavLink className="primaryButton mt-3 mt-lg-0" to="https://backoffice.fxt.exchange/login">
                                        {/* <Button className="primaryButton mt-3 mt-lg-0"> */}
                                        {/* <NavLink to={`${BASE_URL.BASE_URL}Login`}> */}
                                        Login
                                        {/* </Button> */}
                                    </NavLink>
                                    <NavLink className="secondaryButton mt-3 mt-lg-0" to="https://backoffice.fxt.exchange/register">
                                        {/* <Button className=''> */}
                                        {/* <NavLink to={`${BASE_URL.BASE_URL}sign-in`}> */}
                                        Register
                                        {/* </Button> */}
                                    </NavLink>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </header>

            {/* <Outlet /> */}
            {/* <Footer /> */}
        </>
    )
}

export default HeaderNavBar
