import React, { useState, useEffect } from 'react';

const DelayedSuspense = ({ fallback, children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Set a minimum display time for the loader
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 2000); // 3 seconds for example

    return () => clearTimeout(timer);
  }, []);

  if (!isReady) {
    return fallback;
  }

  return (
    <React.Suspense fallback={null}>
      {children}
    </React.Suspense>
  );
};

export default DelayedSuspense;
